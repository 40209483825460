type ComputeApiCacheKeyParams = string | string[][] | Record<string, string> | URLSearchParams | undefined

/**
 * Compute an api cache key, to use with `api_cache` getters
 * Filtering query params (removing undefined, null, and empty) and concat them to url.
 */
export const computeApiCacheKey = (url: string, params: Record<string, unknown> = {}): string => {
  const searchParams = new URLSearchParams(params as ComputeApiCacheKeyParams)

  searchParams.forEach((value, key) => {
    if (typeof value === 'undefined' || value === 'null' || value === '') {
      searchParams.delete(key)
    }
  })

  const query = searchParams.toString()

  return `${url}${query.length > 0 ? '?' + query : ''}`
}
